<template>
  <div style="height: 100vh" class="px-8 pt-10">
    <default-app-bar @click="">
      <template v-slot:title>
        <div class="text-h3">Consultas</div>
      </template>

      <template v-slot:actions>
        <router-link to="/admin/solicitudes/list-desembolsado" color="primary" class="mr-2">
          Volver
        </router-link>
        <a small color="primary" class="btn-margin0" elevation="1" v-on:click="print">
          <v-icon small>mdi-file-pdf </v-icon>
        </a>
      </template>
    </default-app-bar>

    <v-row no-gutters>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" md="6" v-if="$store.getters.hasCreditos()">
            <v-card class="mx-auto mt-0" outlined>
              <v-toolbar flat dense><v-toolbar-title class="mb-3">Créditos</v-toolbar-title></v-toolbar>
              <v-list-item two-line to="/admin/solicitudes/list-desembolsado-owner">
                <v-list-item-content>
                  <div class="kn-item mb-3">
                    <div class="icon">
                      <v-icon color="primary">mdi-currency-usd</v-icon>
                    </div>
                    <div class="content">
                      Pago total <br />
                      <b>{{ $filters.currency(info.total_creditos) }}</b>
                    </div>
                  </div>
                  <div class="kn-item">
                    <div class="icon"><v-icon color="primary">mdi-calendar</v-icon></div>
                    <div class="content mt-2">
                      <span>Fecha Saldo</span>
                      <span class="font-weight-bold">{{ $filters.date(info.fecha) }}</span>
                    </div>
                  </div>
                </v-list-item-content>

                <v-list-item-action class="d-flex justify-center">
                  <v-icon class="mr-2">mdi-menu-right</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" v-if="$store.getters.is_investor">
            <template>
              <v-card class="mx-auto mt-0" outlined v-if="info" elevation="0">
                <v-toolbar flat dense><v-toolbar-title class="mb-3">Administración de cartera
                  </v-toolbar-title></v-toolbar>

                <v-list-item two-line to="/admin/solicitudes/list-desembolsado">
                  <v-list-item-content>
                    <div class="kn-item">
                      <div class="icon">
                        <v-icon color="primary">mdi-currency-usd</v-icon>
                      </div>
                      <div class="content">
                        <span>Saldo total cartera activa - <b>{{ info.num_creditos_admon }}</b> crédito(s)</span>
                        <span class="font-weight-bold">{{ $filters.currency(info.total_admon) }}</span>
                      </div>
                    </div>
                    <div class="kn-item">
                      <div class="icon"><v-icon color="primary">mdi-calendar</v-icon></div>
                      <div class="content mt-2">
                        <span>Fecha de saldo</span>
                        <span class="font-weight-bold">{{ $filters.date(info.fecha) }}</span>
                      </div>
                    </div>

                  </v-list-item-content>

                  <v-list-item-action class="d-flex justify-center">
                    <v-icon class="mr-2">mdi-menu-right</v-icon>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <div class="kn-item">
                      <div class="icon">
                        <v-icon color="primary">mdi-cash</v-icon>
                      </div>
                      <div class="content">
                        <span>Intereses generados - {{ info.ingresos_generados.periodo }}</span>
                        <span class="font-weight-bold">{{ $filters.currency(info.ingresos_generados.total) }}</span>
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line to="/admin/solicitudes/list-aprobado-inver">
                  <v-list-item-content>
                    <div class="kn-item">
                      <div class="icon">
                        <v-icon color="primary">mdi-hand-back-left-outline</v-icon>
                      </div>
                      <div class="content">
                        <span>Solicitudes por gestionar - <b>{{ info.num_solicitudes_admon }}</b> solicitudes</span>
                        <span class="font-weight-bold">{{ $filters.currency(info.total_solicitudes_admon) }}</span>
                      </div>
                    </div>
                  </v-list-item-content>

                  <v-list-item-action class="d-flex justify-center">
                    <v-icon class="mr-2">mdi-menu-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-card>
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Dashboard-->
    <!-- <Dashboard /> -->
    <!-- END | Dashboard -->

    <!-- <div class="mt-4 mx-2">
      <v-card class="mx-auto" max-width="344" v-if="$store.getters.hasCreditos()">
        <v-toolbar color="primary" dark dense><v-toolbar-title>Créditos</v-toolbar-title></v-toolbar>
        <v-list-item two-line to="/admin/solicitudes/list-desembolsado-owner">
          <v-list-item-content>
            Pago total <br />
            <b>{{ $filters.currency(info.total_creditos) }}</b>
            <br />
            Fecha Saldo<br />
            {{ $filters.date(info.fecha) }}
          </v-list-item-content>

          <v-list-item-action class="d-flex justify-center">
            <v-icon class="mr-2">mdi-menu-right</v-icon>

          </v-list-item-action>
        </v-list-item>
      </v-card>

      <template v-if="$store.getters.is_investor">
        <v-card class="mx-auto" max-width="344" outlined v-if="info">
          <v-toolbar color="primary" dark dense><v-toolbar-title>Administración de Cartera
            </v-toolbar-title></v-toolbar>

          <v-list-item two-line to="/admin/solicitudes/list-desembolsado">
            <v-list-item-content>
              Saldo total cartera activa -
              {{ info.num_creditos_admon }} crédito(s) <br />
              {{ $filters.currency(info.total_admon) }}
              <br />
              Fecha Saldo<br />
              {{ $filters.date(info.fecha) }}
            </v-list-item-content>

            <v-list-item-action class="d-flex justify-center">
              <v-icon class="mr-2">mdi-menu-right</v-icon>
            </v-list-item-action>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              Intereses generados - {{ info.ingresos_generados.periodo }} <br />
              {{ $filters.currency(info.ingresos_generados.total) }}
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line to="/admin/solicitudes/list-aprobado-inver">
            <v-list-item-content>
              Solicitudes por gestionar -
              {{ info.num_solicitudes_admon }} solicitudes
              <br />
              {{ $filters.currency(info.total_solicitudes_admon) }}
            </v-list-item-content>

            <v-list-item-action class="d-flex justify-center">
              <v-icon class="mr-2">mdi-menu-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </template>
    </div> -->
  </div>
</template>
  
</div>
  </div>
</template>
<script>
import Dashboard from '../serprogreso/components/Dashboard.vue'
export default {
  name: "Home",
  components: {
    Dashboard,
  },
  data: () => ({
    info: null,
  }),

  mounted() {
    setTimeout(() => {
      this.$store.commit("SET_DRAWER", false);
    }, 200);

    this.loadInfo();
  },
  computed: {
    auth() {
      return this.$store.getters.auth;
    },
    org() {
      // console.log(this.$store.state.company)
      return this.$store.state.company;
    },
  },

  methods: {
    loadInfo() {
      this.$http
        .post(
          "api/get-info-home",
          { data: { id: this.auth.personId } },
          this,
          true,
          false
        )
        .then((res) => {
          if (res.success) {
            this.info = res.info;
            this.$store.commit("SET_INFO_CREDITOS", res.info);
          }
        });
    },
    toogleDrawer() {
      this.$store.commit("toggle");
    },
  },
};
</script>

